<template>
    <base-layout title="Coming Right Up!">
    <div v-if="!hasOrder">
        <ion-label style="font-size: 20px; padding: 20px;">Hi {{currentUser}}!</ion-label><br><br><br>
        <ion-label style="font-size: 20px; padding: 20px">You Currently Have No Orders</ion-label>
        <br><br><br>
        <ion-card v-for="(order) in myCancelledOrders" :key="order.id">
        <ion-title :style="statusStyle(order.status)" >{{status(order.status)}}</ion-title>
        <ul style="padding-left: 50px">
            <li style="font-size: 15px" v-for="item in order.items" :key="item.id">
                <label :style="checkStatus(item)">{{item.name}}</label> - <label v-if="item.status != 'Cancelled'" style="font-weight: bold">{{priceFormat(item.price)}}</label> 
                <label v-else style="font-weight: bold; color: red">Item Cancelled</label>
                <ul>
                    <li v-for="(customization, index) in item.customizations" :key="index">
                        {{customization}}
                    </li>
                </ul>
            <br>
            </li>
        </ul>

        <ion-label style="font-size: 15px; padding-left: 37px; font-weight: bold">Total: {{priceFormat(totalPrice(order.id))}}</ion-label>
    </ion-card>
        <ion-button style="padding-left: 20px" @click="goToMenu">Go To Menu</ion-button>
    </div>
    
    <ion-grid>
        <ion-row>
            <ion-col offset-md="2" size-md="8" offset-lg="3" size-lg="6" offset-sm="0" size-sm="11.5" size-xs="11">    
                <ion-card v-for="(order) in myOrders" :key="order.id" class="queue-content" style="border-radius: 30px">
                
                    <ion-card-title style="border: solid 5px #e7bfb1; border-radius: 10px; width: 100%; font-size: 20px; background-color: #e7bfb1">
                        <ion-grid>
                            <ion-row>
                                <ion-col size-sm="2" size-md="1" size-lg="2" size-xs="4.5">         
                                    <ion-avatar v-for="item in order.items" :key="item.id" style="width: 100px; height: 100px; border: solid 4px white">
                                        <ion-img :src="'/assets/' + item.image"  :alt="item.name"></ion-img>
                                    </ion-avatar>
                                </ion-col>
                                <ion-col>                        
                                    <h3 style="font-weight: bold; margin-top: 40px; color: white"> {{status(order.status)}} </h3>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                        
                    </ion-card-title>
                    <ion-card-content>
                        <ion-label v-for="item in order.items" :key="item.id">
                            
                            <h1 :style="checkStatus(item)">{{item.name}}</h1> 
                            
                                <div v-for="(customization, index) in item.customizations" :key="index">
                                    <li > {{customization}} </li>
                                </div>
                        </ion-label>
                

                        <ion-label style="color: green; margin-bottom: 20px; margin-right: 20px; font-weight: bold; float: right; border: solid 2px black">{{priceFormat(totalPrice(order.id))}}</ion-label>
                    </ion-card-content> 
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>

    </base-layout>
</template>

<style>
.queue-content {
   
    background: rgb(250, 249, 249);
    
    position: relative;
    width: 100%;
    
}
    .backgroundH {
        margin-left: 5px;
        margin-right: 5px;
        background: #e7bfb1;
        border-radius: 80px; 
    }
</style>

<script>
//import { IonLabel, IonItemDivider} from '@ionic/vue'

const enUSFormatter = new Intl.DateTimeFormat('en-US', {
    year:  'numeric',
    month: 'long',
    day:   'numeric',
});


export default {
    components: {
        //IonLabel,
        //IonItemDivider
    },
    methods: {
        goToMenu() {
            this.$router.replace('menu')
        },
        checkStatus(x) {
            if (x.status != "Cancelled") {
                return "font-weight: bold"
            } else {
                return "color: red; text-decoration: line-through"
            }
        },
        statusStyle(x) {
            if(x == "New") {
                return "color: black; font-size: 20px; font-weight: 800"
            } else if (x == "In Progress") {
                return "color: #6495ED; font-size: 20px; font-weight: 800"
            }else if (x == "Finished") {
                return "color: green; font-size: 20px; font-weight: 800"
            }else if (x == "Cancelled") {
                return "color: red; font-size: 20px; font-weight: 800"
            }
            return ""
        },
        status(x) {
            if(x == "New") {
                return "In Queue"
            } else if (x == "In Progress") {
                return "Preparing Now"
            }else if (x == "Finished") {
                return "Order Is Ready!"
            }else if (x == "Cancelled") {
                return "Order Cancelled"
            }
            return ""
        },
        totalPrice(x) {
            let price = 0
            if (this.orders) {
                let orders = this.orders
                for (let i = 0; i < orders.length; i++) {
                    if (orders[i].id == x) {
                        let orderItems = orders[i].items
                        for (let u = 0; u < orderItems.length; u ++) {
                            if (orderItems[u].status != "Cancelled")
                                price = price + orderItems[u].price  
                        }
                    }
                }

            }
            return price
        },
        priceFormat(x) {
            let formatted = "$" + x.toFixed(2)
            return formatted
        },
    },
    computed: {
        timeNow() {
            let now = new Date().getTime()
            return now
        },
        today() {
            const nowDate = new Date()
            return enUSFormatter.format(nowDate)
        },
        todayOrders() {
            if (this.allOrders) {
                let unfiltered = this.allOrders
                var filtered = []
                for (let i = 0; i < unfiltered.length; i++) {
                    let num = unfiltered[i].formattedDate
                    let theDate = enUSFormatter.format(num)
                    if (theDate == this.today) {
                        filtered.push(unfiltered[i])
                    }
                }
            
            }
            return filtered
        },
        allOrders() {
            return this.$store.getters.allOrders
        },
        hasOrder() {
            if (this.myOrders.length == 0 || this.myOrders.length == null) {
                return false
            } else {
                return true
            }
        },
        orders() {
            return this.$store.getters.orders
        },
        currentUser() {
            let fullName = this.$store.getters.loggedInUser.name
            let space = fullName.search(" ")
            let firstName = fullName.slice(0, space)

            return firstName
        },
        myOrders() {
            var orders = this.$store.getters.orders;
            if (orders != null) {
                return orders.filter(o => o.userId == this.$store.getters.loggedInUser.id);
            }
            return [];
        },
        myCancelledOrders() {
            if (this.todayOrders) {
                let allOrders = this.todayOrders
                let cancelled = allOrders.filter(f => f.status == "Cancelled").filter(o => o.userId == this.$store.getters.loggedInUser.id)
                // let myCancelled = cancelled.filter(o => o.userId == this.$store.getters.loggedInUser.id)
                
                return cancelled
            }
            return []
        }
    },
    data() {
        return {
            minutes: "(value)"
        }
    },
    mounted() {
        if (this.orders == null) {
            this.$store.dispatch('loadOrders');
            this.$store.dispatch('loadNotCancelled');
        }
        if (this.allOrders == null) {
            this.$store.dispatch('loadAllOrders')
        }
    }    

}
</script>